//const isDev = 0 // dev
//const isDev = 1 // uat
const isDev = 2 // production

const magento = {
  media: isDev !== 2 ? 'https://lambo-bo.techzonite.com/pub/media/catalog/product' : 'https://bo.lamboplace.com/pub/media/catalog/product'
  //media: 'https://bo.lamboplace.com/pub/media/catalog/product'
}

const lamboplace = {
  domain: isDev !== 2 ? 'https://lambo-www.techzonite.com' : 'https://www.lamboplace.com',
  uc: isDev === 0 ? `http://localhost:3002` : isDev === 1 ? 'https://lambo-uc.techzonite.com' : 'https://uc.lamboplace.com'
}

module.exports = {
  lamboplace,
  magento
}
