import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { ReviewStatusList, CommentStatus } from '../../../../constants/review/reviews'
import { authService, reviewService, reviewCommentService } from '../../../../services'
import { formatter, notification, validator } from '../../../../util'

// UI
import { Button, Input, Page, Panel, Select, Spin, Switch, TextArea } from '../../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Tooltip from 'antd/lib/tooltip'
import Carousel from 'antd/lib/carousel'
import Rate from 'antd/lib/rate'
import moment from 'moment'

import './styles.css'

const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option

const R_NA = /NN\/AA/

class ReviewPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      images: [],
      rating: [],
      comments: [],
      loading: false
    }
  }

  componentDidMount () {
    if (this.canRead()) {

      if (this.isEdit()) {
        this.fetchReview()
      }
    } else {
      authService.unauthorizeAction('/review/reviews')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 6, md: 5 },
      wrapperCol: { sm: 14, md: 17 }
    }
    const formShortItemLayout = {
      labelCol: { sm: 6, md: 4 },
      wrapperCol: { sm: 8, md: 8 }
    }
    const selectFormItemLayout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 }
    }
    const { form, history } = this.props
    const { item, loading, images, rating, comments } = this.state
    const { getFieldValue } = form

    return (
      <Page.Content>
        <Page.Header title='Review and Comments' description='Approval action for review and comments.'>
          {this.canSave() ? (
            <Button disabled={loading} onClick={this.handleSave}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Review Details'>
                <div className='review-panel'>
                  <Row>
                    <Col>
                      <div>
                        { images && images.length > 0
                          ? <Carousel className='review-image-carousel'>
                            { images.map((image, index) => {
                              return (
                                <div key={`carousel${index}`}><img className='rating-image' src={image.path} /></div>
                              )}
                            )}
                          </Carousel>
                          : null
                        }
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Input
                        fieldDecorator={{
                          id: 'product_name',
                          options: {
                            initialValue: item.product_name,
                            rules: [
                              { min: 2, message: 'Label must be between 2 and 32 characters' },
                              { max: 32, message: 'Label must be between 2 and 32 characters' },
                              { required: true, message: 'Please enter label' },
                              { whitespace: true, message: 'Please enter label' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'Product'
                        }}
                        input={{
                        }}
                        readOnly={true}
                      />

                      <FormItem
                        {...formItemLayout}
                        label={(
                          <span>
                            Status
                          </span>
                        )}
                      >
                        <Row gutter={8}>
                          <Col md={9}>
                            <Select
                              fieldDecorator={{
                                id: 'status_id',
                                options: {
                                  initialValue: item.status_id,
                                  rules: [
                                    { required: true, message: 'Please select status' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={ReviewStatusList}
                              listFunc={({ name, value }) => (
                                <Option key={value} value={value}>{name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select Status',
                                tabIndex: 1,
                                style: { width: '100%' }
                              }}
                            />
                          </Col>

                        </Row>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label={(
                          <span>
                            Summary Rating
                          </span>
                        )}
                      >
                        <Row gutter={8}>
                          <Col md={24}>
                            { rating && rating.length > 0
                              ? <Rate defaultValue={parseFloat(rating[0].review_average)} disabled />
                              : null
                            }
                          </Col>

                        </Row>
                      </FormItem>

                      <FormItem
                        {...formItemLayout}
                        label={(
                          <span>
                            Detailed Rating
                          </span>
                        )}
                      >
                        <Row gutter={8}>
                          <Col md={8}>
                            { rating && rating.length > 0
                              ? <div>
                                { rating.map((item, index) => {
                                  return (
                                    <div key={`rating${index}`} className='detail-rating-flex'>
                                      <span className='detail-rating-title'>{item.rating_code}</span>
                                      <Rate
                                        defaultValue={parseFloat(item.review_sum)}
                                        style={{ fontSize: 17, color: '#525252' }}
                                        disabled />
                                    </div>
                                  )
                                })}
                              </div>
                              : null
                            }
                          </Col>

                        </Row>
                      </FormItem>

                      <Input
                        fieldDecorator={{
                          id: 'nickname',
                          options: {
                            initialValue: item.nickname,
                            rules: [
                              { min: 2, message: 'Nickname must be between 2 and 64 characters' },
                              { max: 64, message: 'Nickname must be between 2 and 64 characters' },
                              { required: true, message: 'Please enter nickname' },
                              { whitespace: true, message: 'Please enter nickname' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'Nickname'
                        }}
                        input={{
                          tabIndex: 2
                        }}
                        readOnly={true}
                      />

                      <Input
                        fieldDecorator={{
                          id: 'review_title',
                          options: {
                            initialValue: formatter.toNotApplicableText(item.review_title, 'title'),
                            rules: [
                              { max: 128, message: 'Title must be between 2 and 128 characters' },
                              { whitespace: true, message: 'Please enter title' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'Title'
                        }}
                        input={{
                          tabIndex: 3
                        }}
                        readOnly={true}
                      />

                      <TextArea
                        fieldDecorator={{
                          id: 'review_detail',
                          options: {
                            initialValue: formatter.toNotApplicableText(item.review_detail, 'review'),
                            rules: [
                              { max: 150, message: 'Detail must be between 2 and 150 characters' },
                              { whitespace: true, message: 'Please enter key' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'Review'
                        }}
                        input={{
                          autosize: { minRows: 3, maxRows: 5 },
                          tabIndex: 4
                        }}
                        readOnly={true}
                      />

                      <Input
                        fieldDecorator={{
                          id: 'label_title',
                          options: {
                            initialValue: item.label_title || '(No Label)',
                            rules: [
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'Label(s) Tagged'
                        }}
                        input={{
                          tabIndex: 5
                        }}
                        readOnly={true}
                      />

                      <Input
                        fieldDecorator={{
                          id: 'is_recommended',
                          options: {
                            initialValue: item.is_recommended === 1
                              ? 'Yes'
                              : item.is_recommended === 2
                                ? 'No' : null,
                            rules: [
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'User recommend this product'
                        }}
                        input={{
                        }}
                        readOnly={true}
                      />

                      <Input
                        fieldDecorator={{
                          id: 'verified_buyer',
                          options: {
                            initialValue: item.verified_buyer === 1 ? 'Yes' : 'No',
                            rules: [
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'Verified Buyer'
                        }}
                        input={{
                        }}
                        readOnly={true}
                      />

                      <FormItem
                        {...formItemLayout}
                        label={(
                          <span>
                            Helpfulness
                          </span>
                        )}
                      >
                        <Row gutter={8}>
                          <Col md={24}>
                            <div>{`${item.helpful ? item.helpful : '0'} people found this helpful;
                                 ${item.unhelpful ? item.unhelpful : '0'} people found this unhelpful.`}</div>
                          </Col>

                        </Row>
                      </FormItem>


                    </Col>
                  </Row>
                </div>
              </Panel>

              { comments && comments.length > 0
                ? comments.map((comment, index) => {
                  return (
                    <Panel key={`comment${index}`} title={`Comment #${comment.id}`}>
                      <div className='comment-panel'>
                        <Row>
                          <Col>
                            <Input
                              fieldDecorator={{
                                id: 'c.nickname',
                                options: {
                                  initialValue: comment.nickname,
                                  rules: [
                                    { min: 2, message: 'Nickname must be between 2 and 64 characters' },
                                    { max: 64, message: 'Nickname must be between 2 and 64 characters' },
                                    { required: true, message: 'Please enter nickname' },
                                    { whitespace: true, message: 'Please enter nickname' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayout,
                                label: 'Nickname'
                              }}
                              input={{
                                tabIndex: 6
                              }}
                              readOnly={true}
                            />

                            <Input
                              fieldDecorator={{
                                id: 'c.email',
                                options: {
                                  initialValue: comment.email,
                                  rules: [
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayout,
                                label: 'Email'
                              }}
                              input={{
                              }}
                              readOnly={true}
                            />

                            <Input
                              fieldDecorator={{
                                id: 'c.message',
                                options: {
                                  initialValue: comment.message,
                                  rules: [
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayout,
                                label: 'Comment'
                              }}
                              input={{
                              }}
                              readOnly={true}
                            />

                             <Input
                              fieldDecorator={{
                                id: 'c.status',
                                options: {
                                  initialValue: comment.status === CommentStatus.STATUS_APPROVED
                                    ? 'Approved'
                                    : comment.status === CommentStatus.STATUS_REJECTED
                                      ? 'Rejected' : null,
                                  rules: [
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayout,
                                label: 'Status'
                              }}
                              input={{
                              }}
                              readOnly={true}
                            />

                            <div className='comment-approval'>
                              <Button disabled={loading} onClick={() => this.confirmApprove(comment.id, true)}>Approve</Button>
                              <span style={{ marginRight: 10 }} />
                              <Button disabled={loading} ghost onClick={() => this.confirmApprove(comment.id, false)}>Reject</Button>
                            </div>

                            {/* { comment.status === CommentStatus.STATUS_PENDING
                              ? <div className='comment-approval'>
                                <Button disabled={loading} onClick={() => this.confirmApprove(comment.id, true)}>Approve</Button>
                                <span style={{ marginRight: 10 }} />
                                <Button disabled={loading} ghost onClick={() => this.confirmApprove(comment.id, false)}>Reject</Button>
                              </div>
                              : null
                            } */}

                          </Col>
                        </Row>
                      </div>
                    </Panel>
                  )
                })
                : null }

                <div style={{height: '30px'}} />

            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  checkImage (file) {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  fetchReview = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })
      const item = await reviewService.get(id)
      const images = await reviewService.getImageByReviewId(id)
      const rating = await reviewService.getRatingByReviewId(id)
      const comments = await reviewCommentService.getByReviewId(id)

      this.setState({ item, images, rating, comments, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load review successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  confirmApprove = (id, isApprove) => {
    const that = this
    confirm({
      title: `Confirm to ${isApprove ? 'approve' : 'reject'} this comment?`,
      content: 'Press OK to continue, Cancel to return',
      iconType: 'exclamation-circle',
      onOk () {
        that.handleSaveComment(id, isApprove)
      }
    })
  }

  handleSaveComment = async (id, isApprove) => {
    const { match } = this.props
    const { params } = match
    const { id: reviewId } = params
    const values = {}
    values.status = isApprove ? CommentStatus.STATUS_APPROVED : CommentStatus.STATUS_REJECTED

    const response = await reviewCommentService.save(id, values)

    if (response._id) {
      const newComments = await reviewCommentService.getByReviewId(reviewId)
      notification.show('success', 'Success', `Comment has been ${isApprove ? 'Approved' : 'Rejected'}.`)
      this.setState({ comments: newComments, loading: false })
    } else {
      notification.show('error', 'Unable to perform action', 'Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleSave = () => {
    const { form, history } = this.props
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })

        try {

          if (this.isEdit()) {
            try{
              if(values && values.status_id === 1 && item && !item.approved_at){
                values.approved_at = moment().utc().format()
              }
            }catch(err){
              console.log("err ",err)
            }
            const response = await reviewService.save(item.review_id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Review saved successfully.')
              history.replace('/review/reviews')
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save review successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUploadImage (info) {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      item.image_thumb = response.thumbUrl
      //console.log('done', info.file)
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canDelete = () => {
    return this.hasAccess('deleteReviewTitle')
  }

  canRead = () => {
    return this.hasAccess('readReviewTitle')
  }

  canSave = () => {
    return this.hasAccess(this.isEdit() ? 'updateReviewTitle' : 'createReviewTitle')
  }

  canUpload = () => {
    return this.canSave()
  }

  isEdit = () => {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return {
    ...state.Review
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReviewPage))
