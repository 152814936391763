import React, { Component } from 'react'
import { connect } from 'react-redux'
import authService from '../../../../services/auth'
import { productService, templateService } from '../../../../services'
import notification from '../../../../util/notification'
import validator from '../../../../util/validator'
import AlignmentDisplay, { AlignmentList } from '../../../../constants/collection/alignment'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import { lamboplace, magento } from '../../../../config'
import OptionModal from "antd/lib/modal"
import { sessionStorage } from '../../../../util'
// UI
import { Button, DatePicker, Input, List, Page, Pager, Panel, SearchInput, Select as WDSelect, Spin,  Switch as WDSwitch } from '../../../../components'
import Select from "antd/lib/select";
import UploadHolder from '../../../../components/UploadHolder'
import WDSelectSearch from "../../../../components/SelectSearch";
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import AntButton from 'antd/lib/button'
import { Checkbox } from 'antd'
import './styles.css'
import { values } from 'lodash'

const filterKey = "collection.filter"
const pageSize = 100
const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option
const newfilterKey = 'product.filter'
const pageKey = 'product.page'

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
class CollectionPage extends Component {
  constructor (props) {
    super(props)
    let isPromo = this.props.isPromotion
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      maxPosition: 0,
      item: { sections: [] },
      loading: false,
      loadingImage: false,
      modal: {
        isAdd: true,
        show: false
      },
      optionModal: {
        isAddOptionModal: true,
        selectedProductOptionModal: {},
        showOptionModal: false
      },
      param: {  
        brand: "",
        category: "",
        keyword: "",
        newArrival: "",
        discountFrom:  0,
        discountTo: 0,
        discountPeriodFrom:  "",
        discountPeriodTo: "",
        priceFrom: 0,
        priceTo: 0,
        isPromo,
      },
      products: { data: [], total: 0 },
      productImagePreview: '',
      sections: [],
      selectedProduct: {},
      selectedSection: {
        title: ''
      },
      showBannerModal: false,
      showProductModal: false,
      showSectionModal: false,
      showItemLimit: false
    }
    this.onChangeTitle = this.onChangeTitle.bind(this)
    this.handleSeoChange = this.handleSeoChange.bind(this)
    this.selectFilter = this.selectFilter.bind(this)
    this.updateData = this.updateData.bind(this);
  }

  componentDidMount () {
    this.fetchProducts()

    if (this.canRead()) {
      if (this.isEdit()) {
        this.fetchTemplate()
      } else {
        this.setState({showItemLimit: true})
      }
    } else {
      authService.unauthorizeAction('/notifications')
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ selectedSection }) => ({
      selectedSection: { ...selectedSection, products: arrayMove(selectedSection.products, oldIndex, newIndex) }
    }))
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 5, md: 5 },
      wrapperCol: { sm: 14, md: 14 }
    }
    const modalItemLayout = {
      labelCol: { sm: 5, md: 5 },
      wrapperCol: { sm: 16, md: 16 }
    }
    const { form, history, readOnly=false } = this.props
    const { item = { sections: [] }, currentPage, filter, fullUrl, loading, loadingImage, modal, optionModal, products, productImagePreview, selectedSection, showBannerModal, showProductModal, showSectionModal, showItemLimit, selectedProduct } = this.state
    const { getFieldValue } = form
    const { active } = filter
    const { isAdd, show } = modal
    const { isAddOptionModal, selectedProductOptionModal, showOptionModal } = optionModal
    const { data: productList } = products
    const sectionFirstIndex = 0
    const sectionLastIndex = item.sections.length - 1

    const SortableItem = SortableElement(({ value }) => (
      <li className='edit-product'>
        <img className='product-image-small' src={value.image} />
        {this.canDelete() ? <div className='delete-product' onClick={() => this.deleteProduct(value.sku)}>x</div> : null}
      </li>
    ))

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul className='add-product-list'>
          {this.canSave() ? (
            <li className='add-product' onClick={() => this.showProductModal(true)}>
              <img src='/icon/add.svg' style={{ width: 30, height: 30 }} />
            </li>
          ) : null}
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </ul>
      )
    })

    const formItemFullLayout = {
      labelCol: { sm: 4, md: 4 },
      wrapperCol: { sm: 20, md: 20 }
    }
    
    const formItemLayer = {
      labelCol: { sm: 24, md: 24 },
      wrapperCol: { sm: 24, md: 24 }
    }

    const columns = [
      {
        width: 1,

        render: row => {
          const { isChecked } = this.state;
          return (
            <Checkbox checked={isChecked!=null && isChecked.includes(row.sku)} value={row.sku} onChange={(e)=>this.handlechange(e)}/>
          );
        }
      },
      {
        title: 'Sku',
        key: 'sku',
        width: 2,
      },
      {
        title: 'Image',
        cssClassName: 'center-header',
        align: 'center', 
        key: 'thumbnail',
        render: (row) => {
          return(
          <img
            className={"option-modal-img"}
            alt=""
            src={
              row.thumbnail
                ? magento.media + row.thumbnail
                : "/img/default-image.png"
            }
          />
          )
        },
                  
        width: 4,         
      },
      {
        title: 'Name',
        key: 'name',
        width: 3,
      },
      {
        title: 'Brand',
        key: 'brand',
        width: 3,
      },
      {
        title: 'Category',
        key: 'category',
        width: 3,
      },
      {
        title: 'Price',
        key: 'price',
        width: 2,
      },
      {
        title: 'Discount',
        key: 'discount',
        width: 2,
      },
      {
        title: 'Discount period',
        key: 'discount_period',
        width: 4,
      }
    ]

    return (
      <Page.Content>
        <Page.Header title='Collection Page' description='Set cover image, sections and products for collection'>

          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Page Details' subtitle={fullUrl ? <div style={{ fontSize: '10pt', fontWeight: 'normal' }}><b>Link</b> &nbsp; <span style={{ padding: '2px 15px', background: '#eee', borderRadius: '8px' }}>{fullUrl}</span></div> : null}>
                <Row>
                  <Col lg={16}>
                    <Input
                      fieldDecorator={{
                        id: 'title',
                        options: {
                          initialValue: item.collection,
                          rules: [
                            { min: 2, message: 'Title must be between 2 and 128 characters' },
                            { max: 128, message: 'Title must be between 2 and 128 characters' },
                            { required: true, message: 'Please enter title' },
                            { whitespace: true, message: 'Please enter title' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Title'
                      }}
                      input={{
                        tabIndex: 1,
                        onChange: this.onChangeTitle
                      }}
                      readOnly={!this.canSave()}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={16}>
                    <Input
                      fieldDecorator={{
                        id: 'seoTitle',
                        options: {
                          initialValue: item.seoTitle === 'undefined' ? '' : item.seoTitle
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'SEO Title'
                      }}
                      input={{
                        tabIndex: 1,
                        onChange: this.handleSeoChange
                      }}
                      readOnly={!this.canSave()}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={16}>
                    <Input
                      fieldDecorator={{
                        id: 'desktopUrl',
                        options: {
                          initialValue: item.desktopUrl === 'undefined' ? '' : item.desktopUrl
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Desktop Url'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />
                  </Col>
                </Row>
              </Panel>
              <div className='template-config'>Page Configuration</div>
              <div className='template-container' style={{ marginTop: '50' }}>
                <div className='banner' onClick={() => this.toggleBannerModal()}><img src={item.banner} className='cover' /></div>
                { item.sections ? item.sections.map((section, index) => {
                    return (
                      <div key={index}>
                        <div className='template-section' onClick={() => this.toggleSectionModal(section)}>
                          <div className='section-header'>
                            <div className='title'>{ section.title}</div>
                            <div className='position'>
                              { index !== sectionFirstIndex
                                ? <AntButton type='primary' icon='caret-up' size='small' onClick={(e) => {
                                    e.stopPropagation()
                                    this.handleDecreasePosition(section._id)
                                  }} />
                                : null
                              }
                              { index !== sectionLastIndex
                                ? <AntButton type='primary' icon='caret-down' size='small' onClick={(e) => {
                                    e.stopPropagation()
                                    this.handleIncreasePosition(section._id)
                                  }} />
                                : null
                              }
                            </div>
                          </div>

                          <img className='cover' src={section.cover} />
                          <div>
                            <div className='product-list'>
                              {
                                section.products.map((product, i) => (
                                  <div key={i} className='product'>
                                    <img className='product-image-medium' style={{ background: `url('/img/default-image.png')`, backgroundSize: 'contain' }} src={product.image} />
                                    <div className='product-name'>{ product.name }</div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>

                        </div>
                      </div>
                    )
                  }) : null
                }

                {this.canSave() ? (
                  <div className='add-section' onClick={() => this.addSection()}>Add New Section</div>
                ) : null}
              </div>

              <Modal visible={showBannerModal}
                width={600}
                title='Section Cover Image' onOk={() => this.toggleBannerModal()}
                onCancel={() => this.toggleBannerModal()}
                footer={[
                  <Button key='back' ghost onClick={() => this.toggleBannerModal()}>Cancel</Button>,
                  this.isEdit() && this.canDelete() ? <Button key='delete' onClick={() => this.deleteSectionCover()}>Delete</Button> : null,
                  this.canSave() ? <Button key='save' type='primary' onClick={() => this.toggleBannerModal()}>Confirm</Button> : null
                ]}>
                <Form layout='vertical'>
                  <Row gutter={16}>
                    <Col lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                      <div >
                        <UploadHolder
                          img={{
                            alt: 'Image',
                            src: item.banner || ''
                          }}
                          loading={loadingImage}
                          readOnly={!this.canSave()}
                          height={150}
                          width={450}
                          upload={{
                            action: '/api/templates/upload/banner',
                            beforeUpload: (file) => this.checkImage(file),
                            data: { _id: item._id },
                            disabled: loading,
                            headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                            name: 'image',
                            onChange: (info) => this.handleUploadBanner(info),
                            showUploadList: false
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className='template-cover-img-hint'>
                        Size: 1200px x 400px (JPG or PNG) <br />
                        Maximum file size: 2 MB
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Modal>

              <Modal visible={showSectionModal}
                width={760}
                style={{ top: 15 }}
                title='Section Configuration' onOk={() => this.toggleSectionModal()}
                onCancel={() => this.toggleSectionModal()}
                footer={[
                  <Button key='back' ghost onClick={() => this.toggleSectionModal()}>Cancel</Button>,
                  this.canDelete() ? <Button key='delete' onClick={() => this.deleteSection()}>Delete</Button> : null,
                  this.canSave() ? <Button key='submit' type='primary' onClick={() => this.saveSection()}>Confirm</Button> : null
                ]}>
                <Form layout='vertical'>
                  <Row gutter={16}>
                    <Col lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                      <div >
                        <UploadHolder
                          img={{
                            alt: 'Image',
                            src: selectedSection.cover || ''
                          }}
                          loading={loadingImage}
                          readOnly={!this.canSave()}
                          height={130}
                          width={450}
                          upload={{
                            action: '/api/templates/upload/image',
                            beforeUpload: (file) => this.checkImage(file),
                            data: { _id: selectedSection._id },
                            disabled: loading,
                            headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                            name: 'image',
                            onChange: (info) => this.handleUploadCover(info),
                            showUploadList: false
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className='template-image-hint'>
                        Size: 1200px x 400px (JPG or PNG)
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={24} style={{ marginTop: 25 }}>
                      <Input
                        fieldDecorator={{
                          id: 'sectionTitle',
                          options: {
                            initialValue: selectedSection.title,
                            rules: [
                              { min: 2, message: 'Title must be between 2 and 128 characters' },
                              { max: 128, message: 'Title must be between 2 and 128 characters' },
                              { required: true, message: 'Please enter title' },
                              { whitespace: true, message: 'Please enter title' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...modalItemLayout,
                          label: 'Section Title'
                        }}
                        input={{
                          tabIndex: 1
                        }}
                        readOnly={!this.canSave()}
                      />

                      <WDSelect
                        fieldDecorator={{
                          id: 'alignment',
                          options: {
                            initialValue: selectedSection.alignment || AlignmentDisplay.HORIZONTAL
                          }
                        }}
                        form={form}
                        formItem={{
                          ...modalItemLayout,
                          hasFeedback: false,
                          label: 'Display'
                        }}
                        list={AlignmentList}
                        listFunc={({ name, value }) => (
                          <Option key={value} value={value}>{name}</Option>
                        )}
                        readOnly={!this.canSave()}
                        select={{
                          onChange: this.changeDisplayMode,
                          style: { width: '100%' }
                        }}
                      />

                      { showItemLimit ? (
                          <Input
                            fieldDecorator={{
                              id: 'show_limit',
                              options: {
                                initialValue: selectedSection.show_limit || 10,
                                rules: [
                                  { required: true, message: 'Please enter Limits' },
                                  { validator: this.checkLimits }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...modalItemLayout,
                              label: 'Limits'
                            }}
                            input={{
                              tabIndex: 2
                            }}
                            readOnly={!this.canSave()}
                          />
                        ) : null
                      }
                      <div className='addProduct'>
                      <div className='products-title'>Products</div>
                      <Col lg={22} style={{textAlign: 'right',float:'right'}}>
                            <Button onClick={() => this.showOptionModal(true)}>Add Product(s)</Button>
                      </Col>
                      </div>
                      
                      {/* <ul className='add-product-list'>
                        {this.canSave() ? (
                          <li className='add-product' onClick={() => this.showProductModal(true)}>
                            <img src='/icon/add.svg' style={{width: 30, height: 30}} />
                          </li>
                        ) : null}

                        { selectedSection.products ? selectedSection.products.map((product, index) => (
                          <li key={index} className='edit-product'>
                            <img className='product-image-small' src={product.image} />
                            {this.canDelete() ? <div className='delete-product' onClick={() => this.deleteProduct(product.sku)}>x</div> : null}
                          </li>
                        )) : null }
                      </ul> */}
                      <SortableList axis='xy' items={selectedSection.products} onSortEnd={this.onSortEnd} distance={2} />
                    </Col>
                  </Row>
                </Form>
              </Modal>
              <Modal visible={show}
                className ={'baseModal'}
                width={720}
                title={isAdd ? 'Add Product' : 'Edit Product'} onOk={() => this.hideProductModal()}
                onCancel={() => this.hideProductModal()}
                footer={[
                  <Button key='back' onClick={() => this.hideProductModal()}>Cancel</Button>,
                  this.canSave() ? <Button key='submit' type='primary' onClick={() => this.addProduct()}>Add</Button> : null
                ]}>
                <Form layout='vertical'>
                  <Row gutter={16}>
                    <Col md={24}>
                      <WDSelectSearch
                        labelInValue
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        fieldDecorator={{
                          id: 'sku',
                          options: {
                            initialValue: selectedProduct.sku,
                            rules: [
                              //{ required: true, message: 'Please select a product' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...modalItemLayout,
                          hasFeedback: false,
                          label: 'Products'
                        }}
                        list={productList}
                        listFunc={(product) => {
                          const { name, sku } = product

                          return (
                            <Option className='text-wrap' key={sku} value={sku}>
                              <div>{name}</div>

                              <div className='wd-trending-option-subtitle'>SKU: {sku}</div>
                            </Option>
                          )
                        }}
                        filterKey={filterKey}
                        readOnly = {!this.canSave()}
                        onDoubleClick={
                          this.selectFilter
                        }
                        modalInfo={
                          this.state.modal
                        }
                        select={{
                          onChange: (value, option) => this.selectProduct(value, option),
                          onSearch: (value) => this.fetchProducts(value),
                        }}
                      />
                    </Col>
                    <Col md={24} style={{ textAlign: 'center' }}>
                      <img src={productImagePreview} className='product-image-large' />
                    </Col>
                  </Row>
                </Form>
              </Modal>
              <OptionModal
                visible={showOptionModal}
                width={"80%"}
                title={isAdd ? "Add Product" : "Edit Product"}
                onOk={() => this.hideOptionModal()}
                onCancel={() => this.hideOptionModal()}
                footer={[
                  <Button key="back" onClick={() => this.hideOptionModal()}>
                    Cancel
                  </Button>,
                  this.canSave() ? (
                    <Button
                      key="submit"
                      type="primary"
                      //onClick={() => this.saveProducts(isAddOptionModal, selectedProductOptionModal)}
                      onClick={() => this.addProducts()}
                    >
                      Add
                    </Button>
                  ) : null
                ]}
              >
                <div className="OptionModal">
                <Form layout="vertical" >
                      <Row>
                        <Col lg={7}>
                          <Input
                            fieldDecorator={{
                              id: "brand"
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayer,
                              label: "Brand"
                            }}
                            onChangeEvent={value =>this.updateData("brand",value)}
                            readOnly={readOnly}
                          />
                        </Col>
                        <Col lg={7}>
                          <Input
                            fieldDecorator={{
                              id: "category"
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayer,
                              label: "Category"
                            }}
                            onChangeEvent={(value) =>this.updateData("category",value)}
                            readOnly={readOnly}
                          />
                        </Col>
                        <Col lg={7}>
                          <Input
                            fieldDecorator={{
                              id: "keyword"
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayer,
                              label: "Keyword"
                            }}
                            onChangeEvent={(value) =>this.updateData("keyword",value)}
                            readOnly={readOnly}
                          />
                        </Col>
                        <Col lg={3}>
                          <div className="ant-row ant-form-item wd-input"> 
                            <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 show-label">
                              New arrival?
                            </div>                      
                          <Select
                            allowClear
                            defaultValue={typeof active === 'boolean' ? (active ? 'Yes' : 'No') : undefined}
                            disabled={loading}
                            style={{ width: '100%' }}
                            onChange={value => this.updateData("newArrival",value)}
                          >
                            <Option value='Yes'>Yes</Option>
                            <Option value='No'>No</Option>
                          </Select>
                          </div>
                        </Col>  
                        
                      </Row>
                      <Row>
                        <Col lg={7}>
                          <Col lg={11}>
                            <Input
                              fieldDecorator={{
                                id: "discountfrom",
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayer,
                                label: "Discount"
                              }}
                              onChangeEvent={(value) =>this.updateData("discountFrom",value)}
                              readOnly={readOnly}
                            />
                          </Col>
                          <Col lg={2}>
                            <div className="ant-row ant-form-item wd-input"> 
                              <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 hid-label">
                                a
                              </div>
                              <div >
                                -
                              </div>
                            </div>
                          </Col>
                          
                          <Col lg={11} className="to-hid-label">
                            <Input
                              fieldDecorator={{
                                id: "discountto"
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayer,
                                label: "to",
                                //style:{ visibility:"hidden" }
                              }}
                              onChangeEvent={(value) =>this.updateData("discountTo",value)}
                              readOnly={readOnly}
                            />
                          </Col>
                        </Col>
                        <Col lg={10}> 
                          <Col lg={11}>
                            <DatePicker
                              datePicker={{
                                placeholder: "From",
                                style: { width: "100%" },
                                onChange: value => this.updateData("discountPeriodFrom",value)
                              }}
                              fieldDecorator={{
                                id: "discountshow_from",
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayer,
                                label: "Discount Period"
                              }}
                              readOnly={readOnly}
                            />
                          </Col>
                          <Col lg={2}>
                            <div className="ant-row ant-form-item wd-input"> 
                              <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 hid-label">
                                a
                              </div>
                              <div >
                                -
                              </div>
                            </div>
                          </Col>
                          <Col lg={11} className="to-hid-label"> 
                            <DatePicker
                              datePicker={{
                                placeholder: "To",
                                style: { width: "100%" },
                                onChange: value => this.updateData("discountPeriodTo",value)
                              }}
                              fieldDecorator={{
                                id: "discountshow_to"
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayer,
                                label: "To"
                              }}
                              readOnly={readOnly}
                            />
                          </Col>
                        </Col>
                        <Col lg={7}>
                          <Col lg={11}>
                            <Input
                              fieldDecorator={{
                                id: "pricefrom"
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayer,
                                label: "Price range"
                              }}
                              onChangeEvent={(value) =>this.updateData("priceFrom",value)}
                              readOnly={readOnly}
                            />
                          </Col>
                          <Col lg={2}>
                            <div className="ant-row ant-form-item wd-input"> 
                              <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 hid-label">
                                a
                              </div>
                              <div >
                                -
                              </div>
                            </div>
                          </Col>
                          <Col lg={11} className="to-hid-label">
                            <Input
                              fieldDecorator={{
                                id: "priceto"
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayer,
                                label: "to"
                              }}
                              onChangeEvent={(value) =>this.updateData("priceTo",value)}
                              readOnly={readOnly}
                            />
                          </Col>
                        </Col>
                      </Row>  
                          <button className="wd-button" onClick={()=>this.showProductTable()} >
                            search
                          </button>
                          <Row>
                            <Col>
                                <List cols={columns} rows={products.data}/>
                                <Pager
                                  size={pageSize}
                                  total={products.total}
                                  totalText={`Total ${products.total} products`}
                                  current={currentPage}
                                  onChange={currentPage => this.changeProductPage(currentPage)}
                                  style={{ marginTop: "15px" }}
                                />
                            </Col>
                          </Row>
                </Form>
                </div>
              </OptionModal>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  async renderImage (sku) {
    const image = await productService.getMagentoImage(sku)
    return image.file || '/img/default-image.png'
  }

  async attachImage (item, defaultImage) {
    for (var x = 0; x < item.sections.length; x++) {
      for (var i = 0; i < item.sections[x].products.length; i++) {
        const product = item.sections[x].products[i]

        if (!product.image) {
          if (defaultImage) {
            item.sections[x].products[i].image = defaultImage
          } else {
            item.sections[x].products[i].image = await this.renderImage(product.sku)
          }
          this.setState({ item })
        }
      }
    }
  }

  checkImage (file) {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  checkLimits = (rule, value, callback) => {
    if (value && value.length > 0 && !validator.isDigit(value)) {
      callback(new Error('Limits can contain only numbers'))
    }

    callback()
  }

  async fetchProducts (text) {
    try {
      const { selectedProduct, param } = this.state
      const { sku } = selectedProduct

      const products = await productService.listFilteredProductsByPage(1, 100, {}, param)
      this.setState({ products, loading: false })
    } catch (e) {
      console.error(e)
      this.setState({ loading: false })
    }
  }



  async fetchTemplate () {
    try {
      this.setState({ loading: true })
      const item = await templateService.get(this.props.match.params.id)

      const { sections } = item

      sections.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))

      this.setState({ item })
      this.attachImage(item)
      this.handleSeoChange(item.seoTitle)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load template successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async refreshTemplate () {
    try {
      this.setState({ loading: true })
      const { item } = this.state
      const { sections } = item

      sections.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0))

      this.setState({ item })
      if (this.isEdit()) {
        this.attachImage(item)
      }

      this.setState({ loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load template successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Are you sure you want to delete this template?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await templateService.remove(match.params.id)
          if (response) {
            notification.show('success', 'Deleted successfully', 'Template deleted successfully.')
            history.replace('/pages/collection')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete template successfully. Please try again later.')
        }
      }
    })
  }

  deleteSectionCover () {
    const { match } = this.props
    const { item } = this.state
    const that = this

    confirm({
      title: 'Are you sure you want to delete this section cover image?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await templateService.removeCoverPhoto(match.params.id)

          if (response) {
            item.banner = ''
            that.toggleBannerModal()
            that.setState({ item })
            notification.show('success', 'Delete successfully', 'Section cover image deleted successfully.')
          }
        } catch (e) {
          console.log(e)
          notification.show('error', 'Unable to delete successfully', 'Unable to delete section cover image successfully. Please try again later.')
        }
      }
    })
  }

  async addProduct () {
    const { productImagePreview, selectedProduct, selectedSection } = this.state
    if (!selectedProduct.sku) {
      alert('This product does not have SKU')
      return false
    }

    if (selectedSection.products.find(el => el.sku == selectedProduct.sku)){
      //do nothing
    } else {
      selectedSection.products.push({ sku: selectedProduct.sku, name: selectedProduct.name, image: productImagePreview })
      this.setState({ selectedSection })
    }    

    this.hideProductModal()
  }

  async addProducts () {
    const {form} = this.props
    const {validateFields} = form
    const { isChecked, productImagePreview, selectedProduct, selectedSection, checkedProduct } = this.state
    validateFields(async (errors, values) => {
      values.sku = isChecked
      
        for(let i=0; i<values.sku.length;i++)
        {
          if (selectedSection.products.find(el => el.sku == values.sku[i])){
            //do nothing
          } else {
            //let image = await productService.getMagentoImage(values.sku[i])
      
            /*if (image) {
              let { file } = image
              this.setState({ productImagePreview: file })
              selectedSection.products.push({ sku:values.sku[i], name: checkedProduct[i].name, image: magento.media +checkedProduct.thumbnail })
            } else {
              selectedSection.products.push({ sku:values.sku[i], name: checkedProduct[i].name })
            }*/
            selectedSection.products.push({ sku:values.sku[i], name: checkedProduct[i].name, image: magento.media +checkedProduct[i].thumbnail })
            this.setState({ selectedSection })
            
          }
        }     
      this.hideOptionModal()
    })

    /*if (!selectedProduct.sku) {
      alert('This product does not have SKU')
      return false
    }
    selectedSection.products.push({ sku: selectedProduct.sku, name: selectedProduct.name, image: productImagePreview })

    this.setState({ selectedSection })*/

    //this.hideOptionModal()
  }

  deleteProduct (sku) {
    const that = this
    const { selectedSection } = this.state

    confirm({
      title: 'Are you sure you want to delete this product?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          for (var i = 0; i < selectedSection.products.length; i++) {
            const product = selectedSection.products[i]

            if (product.sku === sku) {
              selectedSection.products.splice(i, 1)
            }
          }

          that.setState({ selectedSection })

          notification.show('success', 'Delete successfully', 'Product deleted successfully.')
        } catch (e) {
          console.log(e)
          notification.show('error', 'Unable to delete successfully', 'Unable to delete product successfully. Please try again later.')
        }
      }
    })
  }

  addSection () {
    const newSection = {
      title: '',
      products: []
    }
    this.toggleSectionModal(newSection)
  }

  deleteSection () {
    const { item, selectedSection } = this.state
    const that = this

    confirm({
      title: 'Are you sure you want to delete this section?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          for (var i = 0; i < item.sections.length; i++) {
            const section = item.sections[i]
            if (section._id === selectedSection._id) {
              item.sections.splice(i, 1)
              break
            }
          }

          that.toggleSectionModal()

          that.setState({ item })

          notification.show('success', 'Delete successfully', 'Section deleted successfully.')
        } catch (e) {
          console.log(e)
          notification.show('error', 'Unable to delete successfully', 'Unable to delete section successfully. Please try again later.')
        }
      }
    })
  }

  saveSection () {
    const { item, selectedSection } = this.state
    const { getFieldValue } = this.props.form

    if (selectedSection.products.length > 0) {
      if (selectedSection._id) {
        for (var i = 0; i < item.sections.length; i++) {
          const section = item.sections[i]
          if (section._id === selectedSection._id) {
            selectedSection.title = getFieldValue('sectionTitle')
            selectedSection.alignment = getFieldValue('alignment')
            selectedSection.show_limit = getFieldValue('alignment') === AlignmentDisplay.HORIZONTAL
              ? getFieldValue('show_limit')
              : null
            item.sections[i] = selectedSection
            break
          }
        }
      } else {
        selectedSection._id = 'temp-' + Math.floor((10 + Math.random()) * 20)
        selectedSection.title = getFieldValue('sectionTitle')
        selectedSection.alignment = getFieldValue('alignment')
        selectedSection.show_limit = getFieldValue('alignment') === AlignmentDisplay.HORIZONTAL
          ? getFieldValue('show_limit')
          : null
        selectedSection.position = item.sections.length ? item.sections.length + 1 : 1
        item.sections.push(selectedSection)
      }

      this.toggleSectionModal()
    } else {
      notification.show('warning', 'Unable to save section', 'Please try to add at least one product.')
    }
  }

  updateData(label, value) {
    const { param } = this.state;
    if(label=="discountPeriodFrom" || label=="discountPeriodTo" || label=="newArrival"){
      param[label] = value;
    } else {
      param[label] = value.target.value;
    }
    
    this.setState({ param });
  }

  async selectFilter (value, modal) {
    this.setState({ selectedProduct: { sku: value, name:modal.name } })
    const image = await productService.getMagentoImage(value)

    if (image) {
      const { file } = image
      this.setState({ productImagePreview: file })
    }
  }

  async selectProduct (value, option) {
    const image = await productService.getMagentoImage(value)

    if (image) {
      const { file } = image
      const { props: optionProps } = option
      const { children: optionPropsChildren } = optionProps
      const { props: optionPropsChildrenProps } = optionPropsChildren[0]
      const { children: name } = optionPropsChildrenProps
      this.setState({ productImagePreview: file, selectedProduct: { sku: value, name } })
    }
  }

  toggleBannerModal () {
    this.setState({
      showBannerModal: !this.state.showBannerModal
    })
  }

  toggleSectionModal (params) {
    this.setState({
      showSectionModal: !this.state.showSectionModal
    })

    if (params) {
      const alignmentValue = params.alignment ? params.alignment : AlignmentDisplay.HORIZONTAL
      const { setFieldsValue } = this.props.form

      this.setState({
        selectedSection: params,
        showItemLimit: alignmentValue === AlignmentDisplay.HORIZONTAL
      })

      setFieldsValue({
        sectionTitle: params.title,
        alignment: alignmentValue,
        show_limit: params.alignment && params.alignment === AlignmentDisplay.HORIZONTAL
          ? (params.show_limit ? params.show_limit : 10)
          : 10
      })
    }
  }

  hideProductModal () {
    const { form } = this.props
    const { modal } = this.state
    modal.show = false
    this.setState({ modal })
    setTimeout(() => {
      form.resetFields()
      this.setState({
        modal: {
          isAdd: true,
          show: false
        },
        selectedProduct: {},
        productImagePreview: ''
      })
    }, 100)
  }

  showProductModal (isAdd, selectedProduct) {
    const { modal,item, selectedSection} = this.state
    const { validateFields } = this.props.form
    // let productImagePreview = '/img/default-image.png'
    modal.isAdd = isAdd
    modal.show = true
    this.fetchProducts()
    this.setState({ modal })

    let newValue = {}, newValueSection = {}
    let jsonArray1 ={}, jsonArray2 = {}
     validateFields(async (errors, values) => {
        for (var key in values){
          if(key == "title"){
            newValue["collection"] =  values[key]
          }else if(key == "seoTitle"){
            newValue["seoTitle"] =  values[key]
          }else if(key == "desktopUrl"){
            newValue["desktopUrl"] =  values[key]
          }else if(key == "sectionTitle"){
            newValueSection["title"] = values[key]
          }
        }

        jsonArray1 = Object.assign(item, newValue);
        jsonArray2 = Object.assign(selectedSection, newValueSection);

        this.setState({item :jsonArray1, selectedSection:jsonArray2})
      })
    // if (isAdd === false) {
    // const { _id, active, image, show_from: from, show_to: to, position, product_sku: sku } = selectedProduct
    // productImagePreview = image ? magento.media + image : '/img/default-image.png'
    // modal.selectedProduct = {
    //   id: _id,
    //   active,
    //   from,
    //   position,
    //   sku,
    //   to
    // }
    // }

    this.setState({ modal })
  }

  changeProductPage(currentPage) {
    const { filterKey } = this.props;
    this.newfetchProducts({ loading: true, currentPage, filterKey });
  }

  handlechange(e) {
    
    let { isChecked, checkedProduct, products } = this.state;
    if(isChecked!=null )
    {
      let a= isChecked.indexOf(e.target.value)
      if(a>=0)
      {
        isChecked.splice(a,1)
        checkedProduct.splice(a,1)
      }
      else {
        isChecked.push(e.target.value)
        checkedProduct.push(products.data.find( x => x.sku == e.target.value))
      }
    }
    else {
      isChecked = [e.target.value]
      checkedProduct = [products.data.find( x => x.sku == e.target.value)]
    }
    this.setState({isChecked, checkedProduct})
  }

  async newfetchProducts({ loading = false, currentPage = 1, filter = {} }) {
    try {
      const { selectedProduct, param } = this.state
      const { sku } = selectedProduct
      sessionStorage.setObject(newfilterKey, filter);
      sessionStorage.setItem(pageKey, currentPage);
      this.setState({ currentPage, loading: loading });
      //const { sku } = selectedProduct;
      const products = await productService.listFilteredProductsByPage(
        currentPage,
        pageSize,
        {},
        param
      );
      this.setState({ products, loading: false });

      //this.setState({ products });
    } catch (e) {
      console.error(e);
    }
  }

  hideOptionModal() {
    let isPromo = this.props.isPromotion;
    const { form } = this.props;
    const { optionModal } = this.state;
    optionModal.showOptionModal = false;
    this.setState({ optionModal });
    setTimeout(() => {
      form.resetFields();
      this.setState({
        optionModal: {
          isAddOptionModal: true,
          selectedProductOptionModal: {},
          showOptionModal: false
        },
        param: {
          brand: "",
          category: "",
          keyword: "",
          newArrival: "",
          discountFrom: 0,
          discountTo: 0,
          discountPeriodFrom: "",
          discountPeriodTo: "",
          priceFrom: 0,
          priceTo: 0,
          isPromo
        },
        currentPage: 1,
        productImagePreview: '',
      });
    }, 100);
  }

  showOptionModal(isAdd, selectedProductOptionModal) {
    const { optionModal } = this.state;
    let productImagePreview = "/img/default-image.png";
    optionModal.isAdd = isAdd;
    optionModal.showOptionModal = true;
    this.fetchProducts();
    this.setState({ optionModal, isChecked:[], checkedProduct:[] });

    if (isAdd === false) {
      const {
        _id,
        active,
        image,
        show_from: from,
        show_to: to,
        position,
        product_sku: sku
      } = selectedProductOptionModal;
      productImagePreview = image
        ? magento.media + image
        : "/img/default-image.png";
      optionModal.selectedProductOptionModal = {
        id: _id,
        active,
        from,
        position,
        sku,
        to
      };
    }

    const { modal,item, selectedSection} = this.state
    const { validateFields } = this.props.form

    let newValue = {}, newValueSection = {}
    let jsonArray1 ={}, jsonArray2 = {}
     validateFields(async (errors, values) => {
        for (var key in values){
          if(key == "title"){
            newValue["collection"] =  values[key]
          }else if(key == "seoTitle"){
            newValue["seoTitle"] =  values[key]
          }else if(key == "desktopUrl"){
            newValue["desktopUrl"] =  values[key]
          }else if(key == "sectionTitle"){
            newValueSection["title"] = values[key]
          }
        }

        jsonArray1 = Object.assign(item, newValue);
        jsonArray2 = Object.assign(selectedSection, newValueSection);

        this.setState({item :jsonArray1, selectedSection:jsonArray2})
      })


    this.setState({ optionModal, productImagePreview });
    //this.setState({ optionModal });
  }

  async showProductTable() {
    await this.fetchProducts();
  }

  changeDisplayMode = (value) => {
    this.setState({ showItemLimit: value === AlignmentDisplay.HORIZONTAL})
  }

  onChangeTitle = (e) => {
    const { setFieldsValue } = this.props.form

    let value = e.target.value
    value = value.toLowerCase().trim().replace(/\s+/g, '-').replace(/\./g, '')

    setFieldsValue({ seoTitle: value })

    this.handleSeoChange(value)
  }

  handleSeoChange (v) {
    if (v === null) return
    const seo = v.target ? v.target.value : v
    const url = `${lamboplace.domain}/collection/${seo}`
    this.setState({ fullUrl: url })
  }

  handleSave () {
    const { history, match } = this.props
    const { validateFields } = this.props.form
    //console.log("handleSave ")
    validateFields(async (errors, values) => {
      //console.log("errors ",errors)
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })
        const data = {}

        try {
          data.name = values.title
          data.desktopUrl = values.desktopUrl
          data.sections = item.sections
          data.seoTitle = values.seoTitle

          if (this.isEdit()) {
            const response = await templateService.save(match.params.id, data)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              this.refreshTemplate()
              notification.show('success', 'Saved successfully', 'Page saved successfully.')
            }
          } else {
            data.image = item.image
            data.image_thumb = item.image_thumb

            const response = await templateService.add(data)
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'Page saved successfully.')
              history.replace(`/pages/collection/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save collection page successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleDeleteImage () {
    const that = this
    const { item } = this.state

    confirm({
      title: 'Are you sure you want to delete this image?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          notification.show('success', 'Deleted successfully', 'Photo deleted successfully.')
          item.photo = {}
          that.setState({ item })
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete photo successfully. Please try again later.')
        }
      }
    })
  }

  handleUploadBanner (info) {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.banner = response.url
      item.image = response.url
      item.image_thumb = response.thumbUrl
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  handleUploadCover (info) {
    const { status, response } = info.file
    const { selectedSection } = this.state
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state

      selectedSection.cover = response.url
      selectedSection.image = response.url
      selectedSection.image_thumb = response.thumbUrl

      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  handleIncreasePosition (id) {
    const { item } = this.state
    const { sections } = item

    const selectedSections = sections.find(x => x._id === id)
    // console.log('Current Selected Sections', selectedSections)

    const selectedSecPosition = selectedSections.position
    // console.log('Selected Section Position', selectedSecPosition)

    // Find Section After Selected Section

    const nextSectionPosition = selectedSecPosition + 1

    const nextSections = sections.find(x => x.position === nextSectionPosition)
    const { _id: nextId } = nextSections

    // console.log('Next Sections', nextSections)
    // console.log('Next Section Position', nextSectionPosition)

    // ALTER SELECTED SECTION SEQUENCE

    // Selected Section
    for (var i in sections) {
      if (sections[i]._id === id) {
        const newPosition = sections[i].position + 1
        item.sections[i].position = newPosition
        break // Stop this loop, we found it!
      }
    }

    // Next Section
    for (var j in sections) {
      if (sections[j]._id === nextId) {
        const newPosition = sections[i].position - 1
        item.sections[j].position = newPosition
        break // Stop this loop, we found it!
      }
    }

    this.setState({ item })
    this.refreshTemplate()

    // setTimeout(() => {
    //   console.log('NEW ITEM', item)
    // }, 1000)
  }

  handleDecreasePosition (id) {
    const { item } = this.state
    const { sections } = item

    const selectedSections = sections.find(x => x._id === id)
    // console.log('Selected Sections', selectedSections)

    const selectedSecPosition = selectedSections.position
    // console.log('Selected Section Position', selectedSecPosition)

    // Find Section Before Selected Section

    const prevSectionPosition = selectedSecPosition - 1

    const prevSections = sections.find(x => x.position === prevSectionPosition)

    const { _id: prevId } = prevSections

    // console.log('Next Sections', prevSections)
    // console.log('Next Section Position', prevSectionPosition)

    // ALTER SELECTED SECTION SEQUENCE

    // Selected Section
    for (var i in sections) {
      if (sections[i]._id === id) {
        const newPosition = sections[i].position - 1
        item.sections[i].position = newPosition
        break // Stop this loop, we found it!
      }
    }

    // Next Section
    for (var j in sections) {
      if (sections[j]._id === prevId) {
        const newPosition = sections[i].position + 1
        item.sections[j].position = newPosition
        break // Stop this loop, we found it!
      }
    }

    this.setState({ item })
    this.refreshTemplate()
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteCollection')
  }

  canRead () {
    return this.hasAccess('readCollection')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateCollection' : 'createCollection')
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CollectionPage))
